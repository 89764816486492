import jQuery from "jquery";

jQuery.fn.extend({
    startSpin: function (text: any) {
        (this as JQuery).html(`<i class='fas fa-spinner fa-spin'></i>&nbsp${text}`);
        (this as JQuery).prop("disabled", true);
    },

    stopSpin: function (text: any) {
        (this as JQuery).html(`${text}`);
        (this as JQuery).prop("disabled", false);
    },
});
